import ApiClient from "../../../shared/api/ApiClient";
import { DASHBOARD, DOWNLOAD_TRIPS_V2, SEARCH_DRIVER } from "../../../shared/api/ApiEndPoint";
import { SearchParams } from "../components/SearchFilterForm";
import { Dashboard, Driver } from "../models/driver";

const searchDriver = async (params: Partial<SearchParams>): Promise<Driver[]> => {
  let headers = {};
  if (params.test_search_error !== null) {
    headers = {
      "X-Test-Error-Flag": `test_search_error=${params.test_search_error}`,
    };
  }
  return ApiClient.post<Driver[]>(SEARCH_DRIVER, params, { headers }).then((res) => res.data);
};

const listDashboard = async (params: Partial<SearchParams>): Promise<Dashboard> => {
  let headers = {};
  if (params.test_search_error !== null) {
    headers = {
      "X-Test-Error-Flag": `test_search_error=${params.test_search_error}`,
    };
  }

  return ApiClient.post<Dashboard>(DASHBOARD, params, {
    headers: headers,
  }).then((res) => res.data);
};

const downloadTrips = async (params: Partial<SearchParams>) => {
  const response = await ApiClient.post(DOWNLOAD_TRIPS_V2, { ...params }, { responseType: "blob" });
  const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = URL.createObjectURL(response.data);
  link.setAttribute("download", decodeURI(filename));
  link.click();
  return null;
};

const liveMonitoringService = {
  searchDriver,
  listDashboard,
  downloadTrips,
};

export default liveMonitoringService;
