import "antd/dist/reset.css";
import "leaflet/dist/leaflet.css";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { store } from "./shared/store/store";
import { DesignToken } from "design-system";
import "./style/font-face.scss";
import "design-system/lib/ds-bundle.css";
import "./style/global.scss";
import AppRouter from "./routers/AppRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#1890ff",
            fontFamily: `${DesignToken.Body2FontFamily} ${DesignToken.Body2FontWeight}`,
            fontSize: DesignToken.Body2FontSize,
            fontSizeHeading1: DesignToken.H1FontSize,
            fontSizeHeading2: DesignToken.H2FontSize,
            fontSizeHeading3: DesignToken.H3FontSize,
            fontSizeHeading4: DesignToken.H4FontSize,
            fontSizeLG: DesignToken.Body1FontSize,
            fontSizeSM: DesignToken.Body1FontSize,
            fontSizeXL: DesignToken.Body1FontSize,
          },
          components: {
            Button: {
              colorPrimary: "#006D2E",
              colorPrimaryBorder: "#006D2E",
              colorPrimaryHover: "#409261",
            },
            DatePicker: {
              colorPrimary: "#006D2E",
              colorPrimaryBorder: "#006D2E",
              colorPrimaryHover: "#409261",
            },
            Spin: {
              colorPrimary: "#006D2E",
            },
            Menu: {
              itemSelectedColor: DesignToken.ContentPrimaryDefault,
              itemSelectedBg: DesignToken.SurfaceStaticSuccessDefault,
              itemActiveBg: DesignToken.CorePaletteBrand10,
              itemHeight: 42,
              itemBorderRadius: 0,
              subMenuItemBg: DesignToken.SurfaceStaticUiDefault,
              itemMarginBlock: 0,
              itemMarginInline: 0,
              subMenuItemBorderRadius: 0,
            },
          },
        }}
      >
        <AppRouter />
      </ConfigProvider>
    </Provider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
