import { Card, CollapseProps, Layout, Row } from "antd";
import { ButtonDs, AccordionDs } from "design-system";
import { DownloadOutlined } from "@ant-design/icons";
import SearchFilterForm, { SearchParams } from "./components/SearchFilterForm";
import liveMonitoringService from "./services/live-monitoring-service";
import LocalstorageService from "../../shared/service/Localstorage.service";
import { GetDateNowBeforeFetchNew } from "../../utils/GetDateNow";
import TomtomMap from "./components/Tomtommap";
import { useEffect, useState } from "react";
import { Driver } from "./models/driver";
import { DashboardCard, DetailSectionProps } from "../../shared/component/CardComponent/DashboardComponent";
import { useQuery } from "@tanstack/react-query";
import { PopoverDetail } from "../../shared/component/CardComponent/PopoverComponent";
import DriverListTable from "./components/DriverListTable";
import LoadingScreenComponent from "../../shared/component/LoadingScreenComponent";
import "./style.scss";

const { Content } = Layout;
const LiveMonitoring = () => {
  const [isToggleMapActive, setIsToggleMapActive] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDashboardActive, setIsDashboardActive] = useState<boolean>(false);
  const toggles = LocalstorageService.getConfigToggleTruckMonitor();
  const [popupDriverKey, setPopupDriverKey] = useState<Driver>();
  const [searchParams, setSearchParams] = useState<Partial<SearchParams>>({
    dc_code: "ALL",
    sla: "",
    sync_status: "",
    task_progress: "",
    truck_types: [],
    truck_plate_number: "",
    ldt_number: "",
    store_number: "",
    test_search_error: null,
  });

  useEffect(() => {
    if (toggles) {
      setIsDashboardActive(toggles.isShowDashborad);
      setIsToggleMapActive(toggles.isShowMap);
    }
  }, []);

  const {
    data: driverDatas = [],
    isLoading: driverLoading,
    error: driverError,
  } = useQuery({
    queryKey: ["drivers", searchParams],
    queryFn: () => liveMonitoringService.searchDriver(searchParams),
    retry: 1,
    refetchOnWindowFocus: false,
  });
  if (driverError) {
    console.log(driverError);
  }

  const {
    data: dashboardsData,
    isLoading: dashboardLoading,
    error: dashboardError,
  } = useQuery({
    queryKey: ["dashboard", searchParams],
    queryFn: () => liveMonitoringService.listDashboard(searchParams),
    retry: 1,
    refetchOnWindowFocus: false,
  });
  if (dashboardError) {
    console.log(dashboardError);
  }

  function allTasks(obj: any) {
    return obj ? Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0) : 0;
  }

  const dashboardDetailData = {
    complete: {
      detailProps: [
        {
          title: "Delivered",
          content: dashboardsData?.task_completed ?? null,
        },
      ],
    },
    incomplete: {
      detailProps: [
        {
          title: "Pending Delivery",
          content: dashboardsData?.task_incompleted ?? null,
        },
      ],
    },
    truck_turn: {
      detailProps: [
        {
          title: "Completed",
          content: dashboardsData?.truck_completed ?? null,
        },
        {
          title: "1 Stop Remaining",
          content: dashboardsData?.truck_remaining_one_task ?? null,
        },
      ],
    },
    not_available: {
      detailProps: [
        {
          title: "N/A",
          content: dashboardsData?.truck_not_avaiable ?? null,
        },
        {
          title: "More Than 1h",
          content: dashboardsData?.truck_inactive ?? null,
        },
      ],
    },
  };

  const returnTotalTrip = (): string => {
    if (
      dashboardsData?.total_trip_by_planned_arrival === undefined ||
      dashboardsData?.total_trip === undefined
    ) {
      return "-";
    }
    return `${dashboardsData?.total_trip} / ${dashboardsData?.total_trip_by_planned_arrival}`;
  };

  const sumTotal = (detailProps: DetailSectionProps[]): string => {
    let result = "";
    let sum = 0;
    detailProps.forEach((x: DetailSectionProps) => {
      if (x.content === undefined || x.content === null) {
        result = "-";
      } else {
        sum += Number(x.content);
        result = sum.toString();
      }
    });
    return result;
  };

  const searchDriver = async (values: SearchParams) => {
    setSearchParams(values);
    setCurrentPage(1);
  };

  const onClickDashboard = (field: { name: string; value: string }) => {
    setSearchParams({ ...searchParams, [field.name]: field.value });
    setCurrentPage(1);
  };

  const onDownloadReport = async () => {
    try {
      await liveMonitoringService.downloadTrips(searchParams);
    } catch (err) {
      console.log(err);
    }
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Task Progress <span className="normal-header">(Last Update: {GetDateNowBeforeFetchNew()})</span>
        </div>
      ),
      children: (
        <div className="wrap-dashboard">
          <Row gutter={[24, 24]} align="stretch" justify="space-between">
            <DashboardCard
              size="big"
              title={"All"}
              content={`${returnTotalTrip()}`}
              color="#EAF6FF"
              popOverContent={PopoverDetail(
                dashboardsData?.task,
                dashboardsData?.fleet_type,
                allTasks(dashboardsData?.task),
              )}
              loading={dashboardLoading}
              dataTestId="dashboard-total-trip"
            />
            <DashboardCard
              size="big"
              title={"Completed"}
              content={dashboardsData?.trip_completed}
              detailProps={dashboardDetailData.complete.detailProps}
              color="#ecf6ed"
              loading={dashboardLoading}
              dataTestId="dashboard-trip-completed"
              unit="Store"
              onClick={() => onClickDashboard({ name: "task_progress", value: "completed" })}
            />
            <DashboardCard
              size="big"
              title={"Incomplete"}
              content={dashboardsData?.trip_incompleted}
              detailProps={dashboardDetailData.incomplete.detailProps}
              color="#fff8e5"
              loading={dashboardLoading}
              dataTestId="dashboard-trip-incompleted"
              unit="Store"
              onClick={() => onClickDashboard({ name: "task_progress", value: "incompleted" })}
            />
            <DashboardCard
              size="small"
              title={"Potential to truck turn"}
              content={sumTotal(dashboardDetailData.truck_turn.detailProps)}
              detailProps={dashboardDetailData.truck_turn.detailProps}
              color="#edf0fe"
              unit="Truck"
              loading={dashboardLoading}
              dataTestId="dashboard-truck-turn"
              onClick={() => onClickDashboard({ name: "task_progress", value: "potential_truck_turn" })}
            />
            <DashboardCard
              size="small"
              title={"Inactive"}
              content={sumTotal(dashboardDetailData.not_available.detailProps)}
              detailProps={dashboardDetailData.not_available.detailProps}
              color="#fdeff4"
              unit="Truck"
              loading={dashboardLoading}
              dataTestId="dashboard-truck-not-available"
              onClick={() => onClickDashboard({ name: "sync_status", value: "inactive" })}
            />
          </Row>
        </div>
      ),
    },
  ];

  return (
    <Content className="management-content live-monitoring-container">
      <div className="warp-header-truck">
        <p>Live Monitoring</p>
        <div>
          <ButtonDs onClick={onDownloadReport}>
            <DownloadOutlined className="icon" />
            Download
          </ButtonDs>
        </div>
      </div>
      <div className="wrap-card-form-search-truck">
        <Card style={{ paddingBottom: "0px", borderRadius: "16px" }}>
          <SearchFilterForm searchParamsValues={searchParams} onSearch={searchDriver} />
        </Card>
      </div>
      <div className="accordian-dashboard-collapse" id="totom-map" data-testid="tomtom-map-testid">
        <AccordionDs
          defaultActiveKey={toggles?.isShowMap ? [1] : []}
          activeKey={isToggleMapActive ? [1] : []}
          style={{ width: "100%" }}
          onChange={(key) => {
            const istoggleActive = key.length > 0;
            setIsToggleMapActive(istoggleActive);

            LocalstorageService.setConfigToggleTruckMonitor({
              isShowDashborad: isDashboardActive,
              isShowMap: istoggleActive,
            });
          }}
          items={[
            {
              key: "1",
              label: (
                <div>
                  Map <span className="normal-header">(Last Update: {GetDateNowBeforeFetchNew()})</span>
                </div>
              ),
              children: (
                <>
                  {driverDatas.length > 0 && (
                    <TomtomMap
                      initialDriverLocations={driverDatas}
                      selectedDriver={popupDriverKey}
                      onClearPopup={(isClear) => {
                        if (isClear) {
                          setPopupDriverKey(undefined);
                        }
                      }}
                    />
                  )}
                  {driverDatas.length === 0 && (
                    <TomtomMap
                      initialDriverLocations={[]}
                      selectedDriver={undefined}
                      onClearPopup={(isClear) => {
                        if (isClear) {
                          setPopupDriverKey(undefined);
                        }
                      }}
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </div>
      <div className="accordian-dashboard-collapse" data-testid="dashboard-testid">
        <AccordionDs
          defaultActiveKey={toggles?.isShowDashborad ? [1] : []}
          items={items}
          style={{ width: "100%" }}
          expandIconPosition="end"
          onChange={(key) => {
            const istoggleActive = key.length > 0;
            setIsDashboardActive(istoggleActive);

            LocalstorageService.setConfigToggleTruckMonitor({
              isShowDashborad: istoggleActive,
              isShowMap: isToggleMapActive,
            });
          }}
        />
      </div>
      <div className="wrap-table-truck-list">
        <Card style={{ borderRadius: "16px" }}>
          <p data-testid="truck-monitor-result-total">
            <b>Truck List</b> <span className="normal-header">(Total {driverDatas.length} trips)</span>
          </p>
          <DriverListTable
            loading={driverLoading}
            driverDatas={driverDatas}
            isDashboardActive={isDashboardActive}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            toggleMap={setIsToggleMapActive}
            setPopupDriverKey={setPopupDriverKey}
          />
        </Card>
      </div>
      <LoadingScreenComponent loading={driverLoading} />
    </Content>
  );
};

export default LiveMonitoring;
