import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DeliveryOrder from "../feature/DeliveryOrder/DeliveryOrder";
import ForgotPassword from "../feature/ForgotPassword/ForgotPassword";
import Geofences from "../feature/Geofences/Geofences";
import GpsTracking from "../feature/GpsTracking/GpsTracking";
import PermissionDenied from "../feature/IrrelevantPage/PermissionDenied";
import MessagingStatus from "../feature/MessagingStatus/MessagingStatus";
import NewStoreMaster from "../feature/NewStoreMaster/NewStoreMaster";
import NewUserManagement from "../feature/NewUserManagement/NewUserManagement";
import UpdateUser from "../feature/NewUserManagement/UpdateUser";
import PodReport from "../feature/PodReport/PodReport";
import TaskStatusReport from "../feature/Report/TaskStatusReport";
import ReportDetail from "../feature/ReportDetail/ReportDetail";
import ResetPassword from "../feature/ResetPassword/ResetPassword";
import ReturnEta from "../feature/ReturnEta/ReturnEta";
import ReturnEtaRequest from "../feature/ReturnEtaRequest/ReturnEtaRequest";
import SelectCompany from "../feature/SelectCompany/SelectCompany";
import TruckMonitor from "../feature/TruckMonitor/TruckMonitor";
import RequireAuth from "./RequireAuth";
import GpsTrackingLayout from "../layout/GpsTrackingLayout";
import Login from "../feature/Login/Login";
import PublicLayout from "../layout/PublicLayout";
import LocalstorageService from "../shared/service/Localstorage.service";
import BookingRequest from "../feature/BookingRequest/BookingRequest";
import BookingDetail from "../feature/BookingRequest/BookingDetail";
import DropMonitoring from "../feature/DropMonitoring/DropMonitoring";
import AgentAccessToken from "../feature/AgentAccessToken/AgentAccessToken";
import { useEffect } from "react";
import { getAuth } from "../shared/api/FirebaseAuth";
import ExternalAPIKey from "../feature/External/ExternalAPIKey/ExternalAPIKey";
import ExternalLogin from "../feature/External/Login/Login";
import UpdateStore from "../feature/NewStoreMaster/UpdateStore";
import LiveMonitoring from "../feature/LiveMonitoring/LiveMonitoring";

const AppRouter = () => {
  const AuthLayout = () => {
    return (
      <RequireAuth>
        <GpsTrackingLayout />
      </RequireAuth>
    );
  };

  const ProtectGuard = ({ children, allowedRoles, allowCompany }: any) => {
    const isExternal = LocalstorageService.getConfigExternal();

    const company = LocalstorageService.getCompany();
    const role = LocalstorageService.getCurrentRole();

    if (!company) {
      if (isExternal) {
        return <Navigate to="/external-api-key" replace={true} />;
      } else {
        return <Navigate to="/select-company" replace={true} />;
      }
    }

    const canAccess = allowedRoles.includes(role) && allowCompany.includes(company);
    if (!canAccess) {
      return <Navigate to="/permission-denied" replace={true} />;
    }
    return children;
  };

  const ExternalProtectGuard = ({ children }: any) => {
    const role = LocalstorageService.getExternalCurrentRole();

    if (role !== "developer") {
      return <Navigate to="/external-permission-denied" replace={true} />;
    }
    return children;
  };

  const ControlTowerProtectGuard = ({ children }: any) => {
    const isExternal = LocalstorageService.getConfigExternal();
    const company = LocalstorageService.getCompany();

    if (isExternal) {
      return <Navigate to="/external-api-key" replace={true} />;
    } else {
      switch (company) {
        case "TD":
          return <Navigate to="/live-monitoring" replace={true} />;
        case "CJ":
          return <Navigate to="/live-monitoring" replace={true} />;
        case "TDM":
          return <Navigate to="/booking-request" replace={true} />;
        default:
      }
    }

    return children;
  };

  // note: don't remove this useEffect case it will effect feature get real-time driver location, onSnapshot fn Firebase
  useEffect(() => {
    getAuth();
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/permission-denied" element={<PermissionDenied />} />
          <Route
            path="/delivery-order"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation"]}
                allowCompany={["CJ", "TD"]}
              >
                <DeliveryOrder />
              </ProtectGuard>
            }
          />
          <Route
            path="/user-management"
            element={
              <ProtectGuard allowedRoles={["admin", "manager"]} allowCompany={["CJ", "TD", "TDM"]}>
                <NewUserManagement />
              </ProtectGuard>
            }
          />
          <Route
            path="/user-management/:user_id"
            element={
              <ProtectGuard allowedRoles={["admin", "manager"]} allowCompany={["CJ", "TD", "TDM"]}>
                <UpdateUser />
              </ProtectGuard>
            }
          />
          <Route
            path="/truck-monitor"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation", "call_center"]}
                allowCompany={["CJ", "TD"]}
              >
                <TruckMonitor />
              </ProtectGuard>
            }
          />
          <Route
            path="/live-monitoring"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation", "call_center"]}
                allowCompany={["CJ", "TD"]}
              >
                <LiveMonitoring />
              </ProtectGuard>
            }
          />
          <Route
            path="/gps-tracking/:tracking_driver_id"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation", "call_center"]}
                allowCompany={["CJ", "TD"]}
              >
                <GpsTracking />
              </ProtectGuard>
            }
          />
          <Route
            path="/report-detail/:tracking_driver_id/:store_number"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation", "call_center"]}
                allowCompany={["CJ", "TD"]}
              >
                <ReportDetail />
              </ProtectGuard>
            }
          />
          <Route
            path="/return-eta"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation"]}
                allowCompany={["CJ", "TD"]}
              >
                <ReturnEta />
              </ProtectGuard>
            }
          />
          <Route
            path="/return-eta-request"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation"]}
                allowCompany={["CJ", "TD"]}
              >
                <ReturnEtaRequest />
              </ProtectGuard>
            }
          />
          <Route
            path="/store-master"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation", "call_center"]}
                allowCompany={["CJ", "TD"]}
              >
                <NewStoreMaster />
              </ProtectGuard>
            }
          />
          <Route
            path="/store-master/:store_number"
            element={
              <ProtectGuard allowedRoles={["admin", "manager"]} allowCompany={["CJ", "TD"]}>
                <UpdateStore />
              </ProtectGuard>
            }
          />
          <Route
            path="/messaging-status"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation"]}
                allowCompany={["CJ", "TD"]}
              >
                <MessagingStatus />
              </ProtectGuard>
            }
          />
          <Route
            path="/task-status-report"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation"]}
                allowCompany={["CJ", "TD"]}
              >
                <TaskStatusReport />
              </ProtectGuard>
            }
          />
          <Route
            path="/geofences"
            element={
              <ProtectGuard allowedRoles={["admin", "planner"]} allowCompany={["CJ", "TD"]}>
                <Geofences />
              </ProtectGuard>
            }
          />
          <Route
            path="/geofences/:driver_mobile_number/:truck_plate_number/:store_number"
            element={
              <ProtectGuard allowedRoles={["admin", "planner"]} allowCompany={["CJ", "TD"]}>
                <Geofences />
              </ProtectGuard>
            }
          />
          <Route
            path="/pod-report"
            element={
              <ProtectGuard
                allowedRoles={["admin", "manager", "planner", "operation"]}
                allowCompany={["CJ", "TD"]}
              >
                <PodReport />
              </ProtectGuard>
            }
          />
          <Route
            path="/booking-request"
            element={
              <ProtectGuard allowedRoles={["admin"]} allowCompany={["TDM"]}>
                <BookingRequest />
              </ProtectGuard>
            }
          />
          <Route
            path="/booking-request/:booking_id"
            element={
              <ProtectGuard allowedRoles={["admin"]} allowCompany={["TDM"]}>
                <BookingDetail />
              </ProtectGuard>
            }
          />

          <Route
            path="/drop-monitoring"
            element={
              <ProtectGuard allowedRoles={["admin"]} allowCompany={["TDM"]}>
                <DropMonitoring />
              </ProtectGuard>
            }
          />

          <Route
            path="/select-company"
            element={
              <ControlTowerProtectGuard>
                <SelectCompany />
              </ControlTowerProtectGuard>
            }
          />
          <Route path="/agent-access-token" element={<AgentAccessToken />} />
          <Route
            path="/"
            element={
              <ControlTowerProtectGuard>
                <Navigate to="/live-monitoring" />
              </ControlTowerProtectGuard>
            }
          />
        </Route>

        <Route element={<PublicLayout />}>
          <Route
            path="/login"
            element={
              <ControlTowerProtectGuard>
                <Login />
              </ControlTowerProtectGuard>
            }
          />
          <Route
            path="/reset-password"
            element={
              <ControlTowerProtectGuard>
                <ResetPassword />
              </ControlTowerProtectGuard>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <ControlTowerProtectGuard>
                <ForgotPassword />
              </ControlTowerProtectGuard>
            }
          />

          <Route path="/external-login" element={<ExternalLogin />} />
          <Route path="/external-permission-denied" element={<PermissionDenied />} />
          <Route
            path="/external-api-key"
            element={
              <ExternalProtectGuard>
                <ExternalAPIKey />
              </ExternalProtectGuard>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
