import { useEffect, useRef, useState } from "react";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import mapSDK from "@tomtom-international/web-sdk-maps";
import { Timestamp, Unsubscribe, collection, onSnapshot, query, where } from "firebase/firestore";
import { getDB } from "../../../shared/api/Firestore";
import { Driver, Progress, StoreProgress } from "../models/driver";
import { Badge, Button, Card, Select, Spin, Steps, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { checkTomTomApiKey, storeProgress } from "../../TruckMonitor/Slice";
import { useNavigate } from "react-router-dom";
import emptyMarker from "../../../assets/images/map/empty_marker.png";
import { RedoOutlined } from "@ant-design/icons";
import LocalstorageService from "../../../shared/service/Localstorage.service";

interface TomtomMapProps {
  initialDriverLocations: Driver[];
  selectedDriver: Driver | undefined;
  onClearPopup: (isClear: boolean) => void;
}

const TomtomMap = ({ initialDriverLocations, selectedDriver, onClearPopup }: TomtomMapProps) => {
  const navigate = useNavigate();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapSDK.Map>({} as mapSDK.Map);
  const markers = useRef<Map<string, any>>(new Map());
  const [popupInfo, setPopupInfo] = useState<Driver | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState<boolean>(false);
  const [storeProgressData, setStoreProgressData] = useState<StoreProgress | undefined>(undefined);
  const [currentLoad, setCurrentLoad] = useState<string | undefined>(undefined);
  const [loadOption, setLoadOption] = useState<{ label: string; value: string }[]>([]);
  const [driverInfo, setDriverInfo] = useState<Driver | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  const selectedTruck = useRef<string>("");
  const selectedMarker = useRef<any>(undefined);

  useEffect(() => {
    onClearPopup(true);
    validateTomTomApiKey();
    try {
      const markerMap = renderTomTomMap();
      const mapContainerDiv = document.getElementById("tomtom-map-container");
      let resizeObserver = new ResizeObserver(() => {});
      if (mapContainerDiv) {
        resizeObserver = new ResizeObserver((entries) => {
          map.current.resize();
        });
        resizeObserver.observe(mapContainerDiv);
      }

      const unsubscribe = getDataDriverLocation(markerMap, initialDriverLocations);
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
        if (mapContainerDiv) {
          resizeObserver.unobserve(mapContainerDiv);
        }
      };
    } catch (error) {
      setIsError(true);
    }
  }, []);

  const validateTomTomApiKey = () => {
    checkTomTomApiKey(process.env.REACT_APP_TOMTOM_API_KEY as string)
      .then(() => {
        setIsError(false);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  useEffect(() => {
    if (selectedDriver) {
      onTogglePopup(selectedDriver);
    }
  }, [selectedDriver]);

  const getStoreProgress = (trip_id: string) => {
    setIsLoadingProgress(true);
    storeProgress(trip_id)
      .then((res) => {
        setStoreProgressData(res);
        setCurrentProgressStep(res?.store_progress ?? []);
        setIsLoadingProgress(false);
      })
      .catch(() => {
        setIsLoadingProgress(false);
      });
  };

  const setCurrentProgressStep = (progressList: Progress[]) => {
    if (progressList.length > 0) {
      const intransitStep = progressList.findIndex((x) => x.status === "in-transit");

      if (intransitStep > 0) {
        setCurrentStep(intransitStep);
        return;
      }

      const defaultStep = progressList.findLastIndex((x) => x.status === "success");
      setCurrentStep(defaultStep);
    }
  };

  const getDataDriverLocation = (markerMap: Map<string, any>, driverInfo: Driver[]): Unsubscribe => {
    const now = Timestamp.now();
    const db = getDB();
    const companyName = LocalstorageService.getCompany();
    const q = query(
      collection(db, `company/${companyName}/tt_driver_location`),
      where("timestamp", ">=", now),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const key = `${data.driver_mobile_number}_${data.truck_plate_number}`;
        if (markerMap.has(key)) {
          const marker = markerMap.get(key);
          if (marker) {
            marker.getElement().id = "active-marker";
            marker.setLngLat([data.long, data.lat]);
            marker.setRotation(data.heading);

            // focus on selected truck
            const truck: Driver | undefined = driverInfo.find(
              (x) => x.truck_plate_number === data.truck_plate_number,
            );
            const truckStatus = truck?.status;
            // change status to active
            if (selectedTruck.current === key && !truckStatus) {
              marker.getElement().classList.add("active");
              if (truck !== undefined && truck) {
                syncModalStatus(truck);
              }
            }
          }
        }
      });
    });
    return unsubscribe;
  };
  const syncModalStatus = (info: Driver) => {
    setDriverInfo((driverInfo) => {
      if (
        driverInfo?.driver_mobile_number === info.driver_mobile_number &&
        driverInfo.truck_plate_number === info.truck_plate_number
      ) {
        return { ...driverInfo, status: true };
      }
      return driverInfo;
    });
  };

  const renderTripInfo = (info: Driver) => {
    setDriverInfo(info);
    setPopupInfo(info);
    getStoreProgress(info.tracking_driver_id);
    setIsModalOpen(true);

    const selectOption = initialDriverLocations
      .filter((f) => f.truck_plate_number === info.truck_plate_number)
      .map((x) => {
        return {
          label: `${x.planned_date} ${x.planned_time}`,
          value: `${x.planned_date} ${x.planned_time}`,
        };
      });

    const uniqStr = new Set(selectOption.map((e) => JSON.stringify(e)));
    const uniqOptions = Array.from(uniqStr).map((e) => JSON.parse(e));

    setLoadOption(uniqOptions);
    setCurrentLoad(`${info.planned_date} ${info.planned_time}`);
  };

  const renderTomTomMap = (): Map<string, mapSDK.Marker> => {
    const ourMap = mapSDK.map({
      key: process.env.REACT_APP_TOMTOM_API_KEY as string,
      container: mapContainer.current as HTMLDivElement,
      center: [100.523186, 13.736717],
      zoom: 4.8,
    });
    map.current = ourMap;

    const markerMap = new Map<string, mapSDK.Marker>();
    initialDriverLocations.forEach((driverLocation) => {
      const markerKey = `${driverLocation.driver_mobile_number}_${driverLocation.truck_plate_number}`;

      if (driverLocation.location.long && driverLocation.location.lat && !markerMap.has(markerKey)) {
        const elementMarker = document.createElement("div");
        elementMarker.id = driverLocation.status ? "active-marker" : "inactive-marker";
        elementMarker.addEventListener("click", () => {
          onTogglePopup(driverLocation);
        });

        const locationMaker = new mapSDK.Marker({
          element: elementMarker,
          rotation: driverLocation.location.heading,
          anchor: "center",
        });

        markerMap.set(
          `${driverLocation.driver_mobile_number}_${driverLocation.truck_plate_number}`,
          locationMaker,
        );

        locationMaker.setLngLat([driverLocation.location.long, driverLocation.location.lat]).addTo(ourMap);
      }
    });

    markers.current = markerMap;
    return markerMap;
  };

  const onTogglePopup = (selectedTrip: Driver) => {
    const markerKey = `${selectedTrip.driver_mobile_number}_${selectedTrip.truck_plate_number}`;
    const marker = markers.current.get(markerKey);

    if (selectedMarker.current) {
      selectedMarker.current.getElement().classList.remove("active");
    }

    if (marker) {
      marker.getElement().classList.add("active");
      selectedTruck.current = markerKey;
      const tomtomMap = map.current;
      tomtomMap.setZoom(15);
      tomtomMap.panTo(marker.getLngLat(), { duration: 1000, animate: true });
      renderTripInfo(selectedTrip);
      selectedMarker.current = marker;
    }
  };

  const storeProgressStatus = (status: string): string => {
    switch (status) {
      case "success":
        return "Success";
      case "waiting":
        return "Waiting";
      case "in-transit":
        return "In-Transit";
      default:
        return "";
    }
  };

  const driverInfoPopup = () => {
    const truckDetailInfo = [
      {
        title: "Truck Plate",
        content: driverInfo?.truck_plate_number,
      },
      {
        title: "Truck Type",
        content: driverInfo?.truck_type,
      },
      {
        title: "Mobile phone",
        content: driverInfo?.driver_mobile_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
      },
      {
        title: "Task Complete",
        content: `${driverInfo?.completed_job}/${driverInfo?.total_job}`,
      },
    ];

    return (
      <div className="marker-popup">
        <CloseOutlined
          style={{ display: "flex", justifyContent: "end" }}
          onClick={() => {
            selectedTruck.current = "";
            setIsModalOpen(false);
            onClearPopup(true);

            if (selectedMarker.current) {
              selectedMarker.current.getElement().classList.remove("active");
            }
          }}
        />
        <Spin spinning={isLoadingProgress}>
          <div className="marker-popup-select-load">
            <p>Plan Load</p>
            <Select
              defaultValue={currentLoad}
              value={currentLoad}
              loading={isLoadingProgress}
              disabled={isLoadingProgress}
              style={{ width: "100%" }}
              data-testid="marker-popup-select-load"
              onChange={(value) => {
                const newLoadData = initialDriverLocations.find(
                  (x) => `${x.planned_date} ${x.planned_time}` === value,
                );
                console.log(newLoadData);

                setCurrentLoad(value);
                setDriverInfo(newLoadData);
                getStoreProgress(newLoadData?.tracking_driver_id ?? "");
              }}
              options={loadOption}
            />
          </div>
          <div className="marker-popup-info-card" data-testid="marker-popup-transport-detail">
            <div className="info-sync-status">
              <p style={{ paddingRight: "10px" }}>Sync Status: </p>
              <span
                className={`popover-status-badge ${driverInfo?.status ? "complete" : "incomplete"}`}
              ></span>
              <p>{driverInfo?.status ? "Active" : "Inactive"}</p>
            </div>

            {isLoadingProgress ? (
              <div className="loading-progress">
                <Card style={{ border: "none", width: "100%", height: "50px" }} loading={true}>
                  xxx <br />
                </Card>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <Steps
                  className="marker-popup-steps"
                  progressDot
                  current={currentStep}
                  size="small"
                  items={
                    storeProgressData?.store_progress?.map((x) => {
                      return {
                        title: x.store_name,
                        description: <div className={`progress-status`}>{storeProgressStatus(x.status)}</div>,
                      };
                    }) ?? []
                  }
                  style={{ margin: "8px 0px", padding: "8px" }}
                />
                {storeProgressData?.remaining_task !== 0 && (
                  <div style={{ position: "absolute", right: "30px", top: "0" }}>
                    <Badge
                      className="step-badge"
                      count={`+${storeProgressData?.remaining_task}`}
                      style={{ backgroundColor: "#F6D9D6", color: "#A83326" }}
                    />
                  </div>
                )}
              </div>
            )}
            {detailSection("Information", truckDetailInfo)}
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={() => {
                  navigate(`/gps-tracking/${driverInfo?.tracking_driver_id}`);
                }}
              >
                Detail
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    );
  };

  const detailSection = (
    title: string,
    info: {
      title: string;
      content?: string;
      child?: React.ReactNode;
    }[],
  ) => {
    return (
      <div className="marker-popup-detail-section">
        <p className="detail-title">{title}</p>
        <div className="marker-popup-detail-section-info">
          {info.map((x, index) => (
            <div className="detail-section-info" key={index}>
              <p>{x.title} :&nbsp;</p>
              <p>{x.content ?? x.content}</p>
              {x.child ?? x.child}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {isError ? (
        <div className="cannot-connect-totommap">
          <img src={emptyMarker} alt="empty-marker" />
          <div className="error-occurred">Error Occured</div>
          <div className="please-try-again">Cannot connect to system, Please try again</div>
          <Button
            className="btn-reconnect"
            onClick={() => {
              window.location.reload();
            }}
            icon={<RedoOutlined />}
          >
            Reload
          </Button>
        </div>
      ) : (
        <div id="tomtom-map-container">
          <div style={{ height: "559px", position: "relative", borderRadius: "8px" }} ref={mapContainer}>
            {isModalOpen ? <>{popupInfo && driverInfoPopup()}</> : <></>}
          </div>
        </div>
      )}
    </>
  );
};

export default TomtomMap;
