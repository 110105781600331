import "./style.scss";
import { Card, Col, Layout, Row, Upload, UploadProps, message, notification } from "antd";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import { DownloadOutlined } from "@ant-design/icons";
import DraggerFileComponent from "../../shared/component/DraggerFileComponent";
import FormSearch from "./components/FormSearch";
import DataTable from "./components/DataTable";
import StoreMasterService from "./services/store-master-service";
import { useEffect, useRef, useState } from "react";
import { SearchStoreMasterParams, StoreMasterParams, StoreMasterType, StoreType } from "./models/store";
import { ButtonDs } from "design-system";
import { UploadFile } from "antd/es/upload/interface";
import TooltipComponent from "../../shared/component/TooltipComponent";
import { NotificationComponent } from "../../shared/component/NotificationComponent";
import AlertMessage from "../../shared/component/AlertMessage";
import LocalstorageService from "../../shared/service/Localstorage.service";
import React from "react";
import storeMasterService from "./services/store-master-service";

const { Content } = Layout;

const NewStoreMaster = () => {
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const userRole = LocalstorageService.getCurrentRole();
  const [dataSource, setDataSource] = useState<StoreMasterType>();
  const [fileUploaded, setFileUploaded] = useState<UploadFile[]>([]);
  const [searchStoreMaster, setSearchStoreMaster] = useState<SearchStoreMasterParams>({
    cursor: null,
    limit: null,
    province: "",
    store_name: "",
    store_number: "",
    enabled_notification: null,
    is_registered: null,
    enabled_checking_geofence: null,
  });
  const [errorMessageFileUpload, setErrorMessageFileUpload] = useState<{
    title: string;
    description: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionNotiFail, setIsActionNotiFail] = useState(false);
  const [isDownloadindFile, setIsDownloadingFile] = useState(false);
  const updatedStoreRef = useRef<StoreType | null>(null);

  useEffect(() => {
    initialData();
    StoreMasterService.signalNotiFail.subscribe((loading) => {
      setIsActionNotiFail(loading);
    });

    return () => {
      StoreMasterService.signalNotiFail.next(false);
      StoreMasterService.signalResetForm.next(false);
    };
  }, []);

  useEffect(() => {
    storeMasterService.storeUpdated.subscribe((updatedStore) => {
      if (updatedStore) {
        NotificationComponent({
          notification: notificationComponent,
          type: "success",
          topic: "แก้ไขข้อมูลร้านค้าสำเร็จ",
        });
        updatedStoreRef.current = updatedStore;
      }
    });
    return () => {
      storeMasterService.storeUpdated.next(null);
      updatedStoreRef.current = null;
    };
  }, []);

  useEffect(() => {
    storeMasterService.signalDeleteStore.subscribe((isDelete) => {
      if (isDelete) {
        NotificationComponent({
          notification: notificationComponent,
          type: "success",
          topic: "ลบร้านค้าสำเร็จ",
        });
      }
    });
    return () => {
      storeMasterService.signalDeleteStore.next(false);
    };
  }, []);

  const initialData = async () => {
    StoreMasterService.isloadingTable.next(true);
    const searchStoreMasterParam: SearchStoreMasterParams = {
      cursor: null,
      limit: 150,
      province: "",
      store_name: "",
      store_number: "",
      enabled_notification: null,
      is_registered: null,
      enabled_checking_geofence: null,
    };
    await StoreMasterService.searchStoreMaster(searchStoreMasterParam).then((res: StoreMasterType) => {
      const updatedStore = updatedStoreRef.current;
      if (updatedStore) {
        const updatedStoreIndex = res.data.findIndex(
          (store) => store.store_number === updatedStore.store_number,
        );
        if (updatedStoreIndex !== -1) {
          res.data.splice(updatedStoreIndex, 1);
        }
        res.data = [updatedStore, ...res.data];
        updatedStoreRef.current = null;
      }
      setDataSource(res);
    });
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    customRequest: ({ onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 500);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading" && status !== "error") {
        setFileUploaded(info.fileList);
        setErrorMessageFileUpload(null);
      }
    },
    beforeUpload: (file: any) => {
      const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        setErrorMessageFileUpload({
          title: "Format ของ File ไม่ถูกต้อง",
          description:
            "ไม่สามารถ Upload ได้เนื่องจาก Format ไม่ถูกต้อง กรุณา Upload เฉพาะไฟล์ .xlsx เท่านั้น",
        });
      }
      return isXlsx || Upload.LIST_IGNORE;
    },
    onRemove(file: UploadFile) {
      const index = fileUploaded.indexOf(file);
      if (index > -1) {
        const recentList = fileUploaded.filter((_, fIdx) => fIdx !== index);
        setFileUploaded(recentList);
      }
    },
  };

  const onSubmit = async () => {
    const fileName = fileUploaded[0].name;
    setIsLoading(true);
    await StoreMasterService.uploadStoreMaster(fileUploaded[0].originFileObj as File)
      .then(() => {
        NotificationComponent({
          notification: notificationComponent,
          type: "success",
          topic: "Upload file สำเร็จ",
          message: `Upload file ${fileName} สำเร็จ`,
        });
        initialData();
      })
      .catch((err) => {
        const responseErrorCode = err.response.data.code;
        const reponseErrorMessage = err.response.data.message;
        const cellErrorResponse = err.response.data.cells;
        if (cellErrorResponse) {
          displayCellErrorMessage(cellErrorResponse);
        } else {
          displayErrorMessage(responseErrorCode, reponseErrorMessage);
        }
      })
      .finally(() => {
        setFileUploaded([]);
        setIsLoading(false);
        StoreMasterService.signalResetForm.next(true);
      });
  };

  const displayErrorMessage = (errorCode: string, message: string) => {
    const errorMessages: { [key: string]: string } = {
      invalid_mobile_phone: "อัพโหลดไฟล์ไม่สำเร็จ เบอร์โทรศัพท์ไม่ถูกต้อง",
    };
    return messageApi.error({
      className: "store-master-message",
      content: errorMessages[errorCode] || `อัพโหลดไฟล์ไม่สำเร็จ ${message}`,
    });
  };

  const displayCellErrorMessage = (cells: { code: string; row: number; col: number }[]) => {
    notification.destroy();
    const errorMessages: { [key: string]: string } = {
      invalid_store_number: "รหัสสาขาไม่ถูกต้อง",
    };
    const cellError = cells.map((cell: { code: string; row: number; col: number }, index: number) => (
      <React.Fragment key={index}>
        <li>
          {errorMessages[cell.code] || "เกิดข้อผิดพลาด"} แถวที่ {cell.row} คอลัมน์ {cell.col}
        </li>
      </React.Fragment>
    ));
    notification.open({
      type: "error",
      className: "upload-store-master noti-error",
      message: "อัปโหลดไฟล์ไม่สำเร็จ",
      description: cellError,
      placement: "top",
      duration: 0,
    });
  };

  const stringToBooleanValue = (value: string) => {
    const stringValue = String(value).toLowerCase();

    switch (stringValue) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return undefined;
    }
  };

  const onSearchFinish = async (value: any) => {
    setIsLoading(true);
    StoreMasterService.isloadingTable.next(true);
    const province: string = value["province"];
    const storeName: string = value["store_name"];
    const storeNumber: string = value["store_number"];
    const enabledNotification: boolean | undefined = stringToBooleanValue(value["enabled_notification"]);
    const registrationStatus: boolean | undefined = stringToBooleanValue(value["registration_status"]);
    const enabled_checking_geofence: boolean | undefined = stringToBooleanValue(
      value["enabled_checking_geofence"],
    );

    const params: SearchStoreMasterParams = {
      cursor: null,
      limit: null,
      province: province ?? "",
      store_name: storeName ?? "",
      store_number: storeNumber ?? "",
      enabled_notification: enabledNotification ?? null,
      is_registered: registrationStatus ?? null,
      enabled_checking_geofence: enabled_checking_geofence ?? null,
    };
    setSearchStoreMaster(params);

    await StoreMasterService.searchStoreMaster(params)
      .then((res: StoreMasterType) => {
        setDataSource(res);
      })
      .catch(() => {
        setDataSource(undefined);
      })
      .finally(() => {
        setIsLoading(false);
        StoreMasterService.isloadingTable.next(false);
      });
  };

  const createFile = (fileName: string, data: Blob) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(data);
    link.setAttribute("download", decodeURI(fileName));
    link.click();
  };

  const onResetTable = () => {
    setIsActionNotiFail(false);
    setSearchStoreMaster({
      cursor: null,
      limit: null,
      province: "",
      store_name: "",
      store_number: "",
      enabled_notification: null,
      is_registered: null,
      enabled_checking_geofence: null,
    });
    initialData();
  };

  const onClickDownload = async () => {
    setIsDownloadingFile(true);
    const params: StoreMasterParams = {
      province: searchStoreMaster.province,
      store_name: searchStoreMaster.store_name ?? "",
      store_number: searchStoreMaster.store_number ?? "",
      enabled_notification: searchStoreMaster.enabled_notification,
      is_registered: searchStoreMaster.is_registered,
      enabled_checking_geofence: searchStoreMaster.enabled_checking_geofence,
    };
    await StoreMasterService.downloadStoreMasterFile(params)
      .then(
        (res) => {
          const filename = res.headers["content-disposition"].split(";")[1].split("=")[1];
          createFile(filename, res.data);
        },
        (error) => {
          console.log("error", error);
        },
      )
      .finally(() => {
        setIsDownloadingFile(false);
      });
  };

  return (
    <DesignSystemTheme>
      <Content className="store-master-content">
        <div className="page-header" style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h1>Store Master</h1>
          <TooltipComponent title="ส่วนข้อมูลหลักเกี่ยวกับร้านค้า" placement="right" />
        </div>
        {userRole?.includes("admin") && (
          <Card>
            <h3>Upload File</h3>
            <DraggerFileComponent
              draggerProps={props}
              contentText="Click or drag file to this area for upload"
              descriptionText="File support .xlsx only (Size not more than 100 MB.)"
              fileUpload={fileUploaded}
              onSubmit={onSubmit}
              fileTemplateHref="/files_download/StoreMaster_Template_File.xlsx"
              errorMessage={errorMessageFileUpload}
              isLoading={isLoading}
              data-test-id="select_file_store_master"
            />
          </Card>
        )}

        <Card style={{ marginTop: 16 }}>
          <Row gutter={16}>
            <Col span={24}>
              <div className="table-section-header">
                <h3>รายการข้อมูลร้านค้า</h3>
                {(userRole?.includes("admin") ||
                  userRole?.includes("manager") ||
                  userRole?.includes("call_center")) && (
                  <ButtonDs
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={onClickDownload}
                    loading={isDownloadindFile}
                    disabled={isLoading}
                  >
                    ดาวน์โหลด
                  </ButtonDs>
                )}
              </div>
              {isActionNotiFail && (
                <AlertMessage
                  type="error"
                  message="เกิดข้อผิดพลาด"
                  description={`ไม่สามารถดำเนินการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง`}
                  margin="24px 0 12px 0"
                />
              )}
            </Col>
            <Col span={24}>
              <FormSearch onSearch={onSearchFinish} onResetSearch={onResetTable} isLoading={isLoading} />
            </Col>
            <Col span={24} className="store-master-table">
              <DataTable
                dataSource={dataSource}
                searchParam={searchStoreMaster}
                onUpdateTable={initialData}
              />
            </Col>
          </Row>
        </Card>
        {contextHolderMessage}
        {contextHolderNoti}
      </Content>
    </DesignSystemTheme>
  );
};

export default NewStoreMaster;
